import React from 'react'

export interface ITexts {
  revtherHeader: string
  revtherDescription: string
  revtherLink: string
  poolsHeader: string
  poolsDescription: string
  poolsLink: string
  designHeader: string
  designDescription: string
  photosHeader: string
  photoDescription: string
  adventureHeader: string
  adventureDescription: string
  adventureLink: string
  commercialHeader: string
  commercialDescription: string
  commercialLink: string
  techHeader: string
  techDescription: string
  techLink: string
  aboutText: string
  techStack: string
  seoCommercial: string
  seoTech: string
  seoAdventure: string
  seoDesign: string
  seoHomepage: string
  seoContact: string
}

export interface IValues {
  texts: ITexts
}
export type ContextProviderTypes = {
  children?: React.ReactNode
}

export const CreateContext = React.createContext<IValues | null>(null)

const ContextProvider: React.FC<ContextProviderTypes> = ({ children }) => {
  const seoCommercial =
    'Looking for stunning automotive photography to enhance your brand or business? Look no further than our professional photography services. Kacper Michalik capture the beauty and power of cars in breathtaking locations, creating images that will leave a lasting impression on your audience. Contact Kacper Michalik today to learn more about how he can elevate your automotive marketing and advertising with expert photography services.'
  const seoAdventure =
    'Looking for stunning photographs of outdoor adventures in breathtaking locations? Look no further than our Adventure Photography page. Kacper Michalik capture the beauty of nature and the excitement of exploration, providing you with unforgettable images to showcase your love for adventure. Contact Kacper Michalik today to learn more about photography services and start planning your next outdoor excursion'
  const seoDesign =
    'Kacper Michalik specialize in creating beautiful and intuitive user interfaces for websites and mobile apps. Kacper understand the importance of combining aesthetics with functionality to create a seamless user experience. Contact Kacper Michalik today to learn more about our web design services and how we can help elevate your online presence.'
  const seoHomepage =
    'Looking for a fullstack developer with expertise in React and Node.js? Kacper Michalik offers comprehensive development services, including front-end development and back-end development. Kacper has the experience and knowledge to help you build powerful and scalable web applications with these cutting-edge technologies. In addition, we can also help you leverage AWS cloud and Ethereum blockchain to create even more powerful and secure web applications.'
  const seoContact =
    'Looking for an experienced fullstack programmer to take your web presence to the next level? Kacper Michalik can help you with everything from front-end development to back-end development and everything in between. Contact Kacper Michalik today to learn more about our services and how Kacper can help bring your vision to life.'
  const seoTech =
    'Explore the digital universe with Kacper Michalik Photography. From high-performance computers and laptops to precision-engineered keyboards, sleek mice, immersive displays, and cutting-edge headphones – our lens captures the essence of tech innovation. Transform your brand visuals; contact us today to pixelate perfection in every gadget shot.'
  const revtherHeader = 'Revther'
  const revtherDescription =
    'Track crypto prices and check NFT portfolio by ETH address'

  const revtherLink = 'https://app.revther.com'
  const poolsHeader = 'Pools'
  const poolsDescription = 'See real time data from Uniswap pools'
  const poolsLink = 'https://pools-app.vercel.app/'
  const designHeader = 'Design'
  const designDescription =
    'Selected work from design field. Mainly UI and web design.'
  const photosHeader = 'Photo&Retouch'
  const photoDescription =
    'I am passionate about photography and have more than 10 years experience in this field. During this time I have mostly focused on two main fields: automotive and adventure photography.'
  const adventureHeader = 'Adventure '
  const adventureDescription =
    'A COLLECTION OF PHOTOS FROM MY TOP VISITED DESTINATIONS'
  const adventureLink = '/adventure'
  const commercialHeader = 'Automotive'
  const commercialDescription =
    'Selected images from my work related to automotive photography'
  const commercialLink = '/automotive'

  const techHeader = 'Tech'
  const techDescription = 'Selected images from tech photography'
  const techLink = '/tech'
  const aboutText =
    'Kacper Michalik is a passionate software engineer with knowledge across variety stacks from dynamic front-end apps, through back-end services via AWS and ETH blockchain. Beyond work, he specializes in adventure photography and is an avid music lover and guitar player.'
  const techStack =
    'TypeScript / React / Next.js / Gatsby / Redux Toolkit / Redux Saga / Apollo / Styled Components / Node.js / NestJS / PostgreSQL / TypeORM / GraphQL / JWT / Jest / Docker / GH Actions / AWS [ Lambda, CloudFormation, S3, API Gateway, RDS, EventBridge, AppSync, CodePipeline ] / Ethereum / Solidity / Hardhat / Ethers / Web3 / TheGraph / OpenZepplin'

  const texts: ITexts = {
    revtherHeader: revtherHeader,
    revtherDescription: revtherDescription,
    revtherLink: revtherLink,
    poolsHeader: poolsHeader,
    poolsDescription: poolsDescription,
    poolsLink: poolsLink,
    designHeader: designHeader,
    designDescription: designDescription,
    photosHeader: photosHeader,
    photoDescription: photoDescription,
    adventureHeader: adventureHeader,
    adventureDescription: adventureDescription,
    adventureLink: adventureLink,
    commercialHeader: commercialHeader,
    commercialDescription: commercialDescription,
    commercialLink: commercialLink,
    aboutText: aboutText,
    techStack: techStack,
    seoAdventure: seoAdventure,
    seoCommercial: seoCommercial,
    seoContact: seoContact,
    seoDesign: seoDesign,
    seoHomepage: seoHomepage,
    techHeader,
    techDescription,
    techLink,
    seoTech,
  }

  const values: IValues = {
    texts: texts,
  }

  return (
    <CreateContext.Provider value={values}>{children}</CreateContext.Provider>
  )
}

export default ContextProvider
